<template>
    <v-card>
        <v-card-title primary-title>
            <v-icon left large>mdi-account-cash-outline</v-icon>
            <span class="headline">Reporte de Ventas</span>
            <v-spacer></v-spacer>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>

          <v-row>
            <v-col class="pb-0 pt-0 " cols="12" md="4">
             
              <v-select
                  v-model="filtro_idvendedor"
                  :items="vendedores"
                  item-text="nombre"
                  item-value="idusuario"
                  menu-props="auto"
                  label="Selecciona al vendedor"
                  hide-details
                  prepend-icon="mdi-face-outline"
                  single-line
                  @click:clear="clearFiltroVendedor"
                  clearable
              ></v-select>
              
            </v-col>
            <v-col class="pb-0 pt-0 " cols="12" md="2">
              <v-dialog
                    ref="dialog_fecha_inicio"
                    v-model="dialog_fecha_inicio"
                    :return-value.sync="fecha_desde"
                    persistent
                    width="290px"
                >
                    <template v-slot:activator="{ on }">
                        
                            <v-text-field
                                v-model="fecha_desde"
                                label="Desde"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-on="on"
                            ></v-text-field>
                        
                    </template>
                    <v-date-picker v-model="fecha_desde" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="dialog_fecha_inicio = false">Cancelar</v-btn>
                    <v-btn text color="primary" @click="$refs.dialog_fecha_inicio.save(fecha_desde)">OK</v-btn>
                    </v-date-picker>
                </v-dialog>

            </v-col>
            <v-col class="pb-0 pt-0 " cols="12" md="2">
              <v-dialog
                    ref="dialog_fecha_hasta"
                    v-model="dialog_fecha_hasta"
                    :return-value.sync="fecha_hasta"
                    persistent
                    width="290px"
                >
                    <template v-slot:activator="{ on }">
                        
                            <v-text-field
                                v-model="fecha_hasta"
                                label="Hasta"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-on="on"
                            ></v-text-field>
                        
                    </template>
                    <v-date-picker v-model="fecha_hasta" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="dialog_fecha_hasta = false">Cancelar</v-btn>
                    <v-btn text color="primary" @click="$refs.dialog_fecha_hasta.save(fecha_hasta)">OK</v-btn>
                    </v-date-picker>
                </v-dialog>

            </v-col>
            <v-col cols="6" md="2" outline>
              <v-btn v-if="canPrint" color="primary" depressed @click="getPdf()" ><v-icon left>mdi-printer</v-icon>Imprimir</v-btn>
            </v-col>
          </v-row>
        </v-card-text>

        <DialogEspera :dialogEspera="dialogEspera"></DialogEspera>

        <SnackbarMensajes v-bind:sb="sb"></SnackbarMensajes>
    </v-card>
</template>

<script>
import moment from 'moment'
import platform from 'platform-detect'
import ApiService from '@/services/ApiService.js'
import SnackbarMensajes from '@/components/SnackbarMensajes.vue'
import DialogEspera from '@/components/DialogEspera.vue'
import axios from 'axios'

export default {
  name: 'RepVentas',
  components: { SnackbarMensajes, DialogEspera },
  data: () => ({
    repartidores: [],
    vendedores: [],
    filtro_pedido: '',
    filtro_idrepartidor: '',
    filtro_idvendedor: '',
    dialog_fecha_inicio: false,
    dialog_fecha_hasta: false,
    fecha_desde: '',
    fecha_hasta: '',
    sb: {
      mostrar: false,
      mensaje: '',
      color: 'success',
      timeout: 3000,
      multiline: false,
    },
    isLoading: true,
    dialogEspera: false,
  }),
  computed: {
    canPrint() {
        return !(platform.ios && platform.pwa);

    },
    isDesktop() {
        return platform.desktop
    },
    formTitle() {
        const title = `Pedido #${this.editedItem.id}`;
        return platform.desktop ? title + ` - ${this.editedItem.vendedor_nombre } - R: ` + (this.editedItem.repartidor ? this.editedItem.repartidor.nombre : '') : title;
    },
  },
  methods: {
    getPdf() {
      this.dialogEspera = true;

      if(!this.filtro_idvendedor) this.filtro_idvendedor = null;
      let filtros = {
        fecha_inicio: this.fecha_desde,
        fecha_fin: this.fecha_hasta,
        idvendedor: this.filtro_idvendedor
      }
      
      ApiService.getPdf(this.$store.getters.token, '/productopedidos/vendidos', filtros)
          .then(response => {
              if (response.status == 200) {
                  this.dialogEspera = false;

                  var popup = window.open();   
                  var link = document.createElement('a');  
                  link.setAttribute('href', 'data:application/pdf;base64, ' + response.data );  
                  link.setAttribute('download', 'repventas_' + Date.now() + '.pdf');  
                  popup.document.body.appendChild(link);   
                  link.click();

              } else {
                  this.showMensaje(
                      `Error al obtener pdf ${response.status} - ${response.statusText} `,
                      'error', true)
              }
          })
          .catch(error => {
              if (error.response) this.showMensaje(
                  `Error al obtener pdf ${error.response.status} - ${error.response.statusText}`,
                  'error', true)
              else this.showMensaje(`Error al obtener pdf ${error.message}`, 'error', true)
              this.dialogEspera = false
          })
          .finally(() => (this.dialogEspera = false))
    },
    clearFiltroVendedor(event){
        this.filtro_idvendedor = null;
    },
    showMensaje(mensaje,color,multiline) {
      this.sb.mensaje = mensaje
      this.sb.multiline = multiline
      this.sb.color = color
      this.sb.mostrar = true
    },
    getCatalogos() {
        this.dialogEspera = true

        let getvendedores = ApiService.getVendedores(this.$store.getters.token);
        let getrepartidores = ApiService.getRepartidores(this.$store.getters.token);

        axios.all([getrepartidores, getvendedores])
            .then(axios.spread((...responses) => {
                if (responses[0].status == 200) {
                    this.repartidores = responses[0].data;
                } else {
                    this.showMensaje(
                        `Error al obtener repartidores ${responses[0].status} - ${responses[0].statusText} `,
                        'error', true)
                }

                if (responses[1].status == 200) {
                    this.vendedores = responses[1].data;
                } else {
                    this.showMensaje(
                        `Error al obtener vendedores ${responses[1].status} - ${responses[1].statusText} `,
                        'error', true)
                }

                this.dialogEspera = false;

            })).catch(error => {
                if (error.response) this.showMensaje(
                    `Error al obtener listado XXX ${error.response.status} - ${error.response.statusText}`,
                    'error', true)
                else this.showMensaje(`Error al obtener XXX listado ${error.message}`, 'error', true)
                this.dialogEspera = false
            })
            .finally(() => (this.dialogEspera = false))
    },
  },
  mounted() {
    this.fecha_desde =  moment().format('YYYY-MM-DD');
    this.fecha_hasta =  moment().format('YYYY-MM-DD');
    this.getCatalogos();
  },
  created() {
  }
}
</script>
